import React, { Component } from 'react'
import HomeHeader from '../components/HomeHeader'
import HomeContent from '../components/HomeContent'
import HomeContact from '../components/HomeContact'
import HomeFooter from '../components/HomeFooter'

export default class HomeModern extends Component {
    render() {
        var detail = "当代服饰设计是一款设计类实用工具APP，你可以自由搭配形制、颜色、暗纹、印花来设计独一无二的当代服饰，也可以浏览其他用户分享的设计汲取灵感";
        return (
            <div style={{padding:'40px'}}>
                <HomeHeader icon='icon_modern.png' title='当代服饰设计' detail={detail}/>
                <HomeContent title='当代服饰设计与参考' subtitle='DIY你的专属当代服饰' ios='ios_qrcode.png' android='android_qrcode.png' snapshot='snapshot_modern.jpeg'/>
                <HomeContact agreement='http://modernfile.iyztp.com/2022/01/20/5405068740ef78dc80da6ea32c76cdd6.html' privacy='http://modernfile.iyztp.com/2022/01/20/a08250794005066c809f9f54ce816a04.html'/>
                <HomeFooter/>
            </div>
        )
    }
}