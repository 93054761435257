import React, { Component } from 'react'
import './HomeContact.css'

export default class HomeContact extends Component {
    render() {
        return (
            <div id='home_contact_container'>
                <span>联系我们: tsc92321@gmail.com</span>
                <a href={this.props.agreement}>用户协议</a>
                <a href={this.props.privacy}>隐私政策</a>
            </div>
        )
    }
}