import React, { Component } from 'react'
import './HomeHeader.css'

export default class HomeHeader extends Component {
    render() {
        return (
            <div id='home_header_container'>
                <div id='home_header_left'>
                    <img src={require('../img/'+this.props.icon).default}/>
                    <span className='home_header_title'>{this.props.title}</span>
                </div>
                <div id='home_header_right'>{this.props.detail}</div>
                {/* <Link id='home_header_right' to='/download'>下载APP</Link> */}
            </div>
        )
    }
}
