import React, { Component } from 'react'
import HomeHeader from '../components/HomeHeader'
import HomeContent from '../components/HomeContent'
import HomeContact from '../components/HomeContact'
import HomeFooter from '../components/HomeFooter'

export default class HomeLolita extends Component {
    render() {
        var detail = "Lotea茶话会是一款设计类实用工具APP，你可以自由搭配形制、颜色、暗纹、印花来设计独一无二的Lolita服饰，也可以浏览其他用户分享的设计汲取灵感";
        return (
            <div style={{padding:'40px'}}>
                <HomeHeader icon='icon_lolita.png' title='Lotea茶话会' detail={detail}/>
                <HomeContent title='Lolita穿搭设计参考' subtitle='DIY你的专属Lolita' ios='ios_qrcode.png' android='android_qrcode.png' snapshot='snapshot_lolita.jpeg'/>
                <HomeContact agreement='http://lolitafile.iyztp.com/2022/01/20/45db3eaa40ebb5bc80b3c3293289c168.html' privacy='http://lolitafile.iyztp.com/2022/01/20/1a53cb3d40a6d94f80698bf9be1a9151.html'/>
                <HomeFooter/>
            </div>
        )
    }
}