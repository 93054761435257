import React, { Component } from 'react'
import HomeContact from '../components/HomeContact'
import HomeContent from '../components/HomeContent'
import HomeHeader from '../components/HomeHeader'

export default class HomeJK extends Component {
    render() {
        var detail = "JK物语是一款设计类实用工具APP，你可以自由搭配形制、颜色、暗纹、印花来设计独一无二的JK服饰，也可以浏览其他用户分享的设计汲取灵感";
        return (
            <div style={{padding:'40px'}}>
                <HomeHeader icon='icon_jk.png' title='JK物语' detail={detail}/>
                <HomeContent title='JKS穿搭设计参考' subtitle='DIY你的专属JKS' ios='ios_qrcode.png' android='android_qrcode.png' snapshot='snapshot_jk.jpeg'/>
                <HomeContact agreement='http://jkfile.iyztp.com/2022/01/20/5e68e980401a0be280664ae5589b1e07.html' privacy='http://jkfile.iyztp.com/2022/01/20/a7c15de940e90dbe806239bc96826708.html'/>
                <div>—————— 成都与子同袍科技有限公司 ——————</div>
                <a href='http://beian.miit.gov.cn/'>蜀ICP备2021023183号</a>
                <img src={require('../img/police.png').default} />
                <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004138'>川公网安备 51019002004138号</a>
            </div>
        )
    }
}