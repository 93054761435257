import React, { Component } from 'react'
import HomeHeader from '../components/HomeHeader'
import HomeContent from '../components/HomeContent'
import HomeContact from '../components/HomeContact'
import HomeFooter from '../components/HomeFooter'

export default class HomeModern extends Component {
    render() {
        var detail = "完形词汇是一款教育类外语学习APP，你可以通过读故事的形式轻松背单词";
        return (
            <div style={{padding:'40px'}}>
                <HomeHeader icon='icon_aiword.png' title='完形词汇' detail={detail}/>
                <HomeContent title='边读故事边背单词' subtitle='让背单词变得轻松' ios='ios_qrcode_aiword.png' android='android_qrcode.png' snapshot='snapshot_aiword.png'/>
                <HomeContact agreement='http://www.iyztp.com/media/service/service_cn_AIWord.html' privacy='http://www.iyztp.com/media/privacy/privacy_cn_AIWord.html'/>
                <HomeFooter/>
            </div>
        )
    }
}