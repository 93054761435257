import React, { Component } from 'react'
import HomeContact from '../components/HomeContact'
import HomeContent from '../components/HomeContent'
import HomeFooter from '../components/HomeFooter'
import HomeHeader from '../components/HomeHeader'

export default class HomeYZTP extends Component {
    render() {
        var detail = "与子同袍是一款设计类实用工具APP，你可以自由搭配形制、颜色、暗纹、印花来设计独一无二的汉服，也可以浏览其他用户分享的设计汲取灵感";
        return (
            <div style={{padding:'40px'}}>
                <HomeHeader icon='icon.png' title='与子同袍' detail={detail}/>
                <HomeContent title='汉服设计与百科' subtitle='DIY你的专属汉服' ios='ios_qrcode.png' android='android_qrcode.png' snapshot='snapshot.jpeg'/>
                <HomeContact agreement='http://file.iyztp.com/2022/01/20/72d6b744406dfcfc8085544b332d7bbe.html' privacy='http://file.iyztp.com/2022/01/20/119b0d7240e3585480793c9df5776273.html'/>
                <HomeFooter/>
            </div>
        )
    }
}
