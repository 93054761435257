import React, { Component } from 'react'
import HomeContact from '../components/HomeContact'
import HomeContent from '../components/HomeContent'
import HomeFooter from '../components/HomeFooter'
import HomeHeader from '../components/HomeHeader'

export default class HomeLotea extends Component {
    render() {
        var detail = "Lotea是一款全平台壁纸工具，你可以浏览并设置自己喜欢的静态或动态壁纸";
        return (
            <div style={{padding:'40px'}}>
                <HomeHeader icon='icon_lotea.png' title='Lotea' detail={detail}/>
                <HomeContent title='潮流壁纸每日更新' subtitle='全平台支持' ios='ios_qrcode_aiword.png' android='android_qrcode.png' snapshot='snapshot_lotea.jpeg'/>
                <HomeContact agreement='http://www.iyztp.com/media/service/service_cn_Lotea.html' privacy='http://www.iyztp.com/media/privacy/privacy_cn_Lotea.html'/>
                <HomeFooter/>
            </div>
        )
    }
}