import React, { Component } from 'react';
import '../App.css';

export default class Home extends Component {
    render() {
        return (
            <div className="App">
            {/* 顶部公司信息 */}
            <header className="App-header">
              <div className="company-info">
                <h1>成都与子同袍科技有限公司</h1>
                <p>专注创新，超越未来</p>
              </div>
            </header>
      
            {/* 中间展示APP产品 */}
            <section className="product-showcase">
              <div className="app-product">
                <img src={require('../img/snapshot.jpeg')} alt="App 1" />
                <h2>与子同袍</h2>
                <p>汉服设计与百科</p>
              </div>
              <div className="app-product">
              <img src={require('../img/snapshot_aiword.png')} alt="App 2" />
                <h2>完形词汇</h2>
                <p>AI背单词</p>
              </div>
              <div className="app-product">
                <img src={require('../img/snapshot_joker.jpeg')} alt="App 3" />
                <h2>符号牌</h2>
                <p>肉鸽卡牌游戏</p>
              </div>
            </section>
      
            {/* 底部公司联系方式和备案信息 */}
            <footer className="App-footer">
              <div className="contact-info">
                <h3>联系我们</h3>
                <p>邮箱：tsc92321@gmail.com</p>
              </div>
              <div className="record-info">
                <a href='http://beian.miit.gov.cn/'>蜀ICP备2021023183号</a>
                <p>
                <img src={require('../img/police.png')} />
                <span> </span>
                <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004138'>川公网安备 51019002004138号</a>
                </p>
              </div>
            </footer>
          </div>
        )
    }
}
