import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Contact from './pages/Contact';
import Download from './pages/Download';
import Home from './pages/Home';
import HomeAIWord from './pages/HomeAIWord';
import HomeJK from './pages/HomeJK';
import HomeLolita from './pages/HomeLolita';
import HomeLotea from './pages/HomeLotea';
import HomeModern from './pages/HomeModern';
import HomeYZTP from './pages/HomeYZTP';

class APP extends React.Component {
  render() {
    return (
      <div style={{backgroundColor:'#eeeeee'}}>
        {/* <Redirect/> */}
        <Switch to='/home'>
          <Route exact path='/' component={Home}/>
          <Route exact path='/zcj' component={HomeYZTP}/>
          <Route exact path='/jk' component={HomeJK}/>
          <Route exact path='/lolita' component={HomeLolita}/>
          <Route exact path='/modern' component={HomeModern}/>
          <Route exact path='/aiword' component={HomeAIWord}/>
          <Route exact path='/lotea' component={HomeLotea}/>
          <Route exact path='/download' component={Download}/>
          <Route exact path='/contact' component={Contact}/>
        </Switch>
      </div>
    )
  }
}

export default APP