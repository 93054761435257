import React, { Component } from 'react'
import './HomeContent.css'

export default class HomeContent extends Component {
    render() {
        return (
            <div id='home_content_container'>
                <div id='home_content_left'>
                    <div>{this.props.title}</div>
                    <div>{this.props.subtitle}</div>
                    <div class='home_content_qrcode'>
                        <div>iOS下载</div>
                        <img src={require('../img/'+this.props.ios).default}/>
                    </div>
                    <div class='home_content_qrcode'>
                        <div>Android下载</div>
                        <img src={require('../img/'+this.props.android).default}/>
                    </div>
                </div>
                <img id='home_content_right' src={require('../img/'+this.props.snapshot)}/>
            </div>
        )
    }
}
