import React, { Component } from 'react'
import './HomeFooter.css'

export default class HomeFooter extends Component {
    render() {
        return (
            <div id='home_footer_container'>
                <div>—————— 成都与子同袍科技有限公司 ——————</div>
                <a href='http://beian.miit.gov.cn/'>蜀ICP备2021023183号</a>
                <img src={require('../img/police.png').default} />
                <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004138'>川公网安备 51019002004138号</a>
            </div>
        )
    }
}
