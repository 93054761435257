import React, { Component } from 'react';
import './Download.css';

export default class Download extends Component {
    /**
     * @name 判断iOS
     */
     isiOS = ()=>{
        let u = navigator.userAgent;
        let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
        return iOs;
    }
    /**
     * @name 判断android
     */
    isAndroid = ()=>{
        let u = navigator.userAgent;
        let android = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        return android
    }

    isWechat = ()=>{
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined'){
            return true;
        } else{
            return false;
        }
      }

    render() {
        if (this.isiOS()) {
            window.location = 'https://apps.apple.com/cn/app/id1475409197'
        } else if (this.isWechat()) {
            return (
                <div style={{height:'calc(100vh)'}}>
                    <img id='download_indicator' src={require('../img/arrow.png').default}/>
                    <div id='download_logo'>
                        <img src={require('../img/icon.png').default}/>
                        </div>
                    <div id='download_text_container'>
                        <span className='download_text'>点击右上角</span>
                        <span className='download_highlight_text'>“...”</span>
                        <span className='download_text'>选择</span>
                        <span className='download_highlight_text'>“在浏览器打开”</span>
                    </div>
                </div>
            )
        } else {
            window.location = 'https://2fa8f0a5a20cb14a862f2b87576cb379.dlied1.cdntips.net/imtt.dd.qq.com/16891/apk/1ABD768CAF38E2A9C86CE8983ABA9622.apk?mkey=6138588bb68a40a1&f=8e4b&fsname=com.tousan.ZCJ_5.2.0_210830.apk&csr=1bbd&cip=182.138.102.84&proto=https'
        }
        return <div/>
    }
}
