import { Alert } from 'antd'
import React, { Component } from 'react'
import './Contact.css'
import copy from 'copy-to-clipboard'
import axios from 'axios'

export default class Contact extends Component {

    state={
        qrcode: ''
    };

    componentDidMount() {
        axios
        .get('/api2/0f3aaf75f74fb6be/setting_get_config')
        .then((res)=>{
            console.log(res.data);
            this.setState({'qrcode': res.data.wechat_qrcode});
        });
    }

    render() {
        return (
            <div id='contact_root'>
                <div className='contact_title'>微信扫描二维码入群</div>
                <img id='contact_qrcode' src={this.state.qrcode} />
                <div id='contact_or'>或（如果二维码过期）</div>
                <div className='contact_title'>添加微信好友入群</div>
                <div id='contact_wechat_code'> 
                    <span>yztp321</span>
                </div>
                <button id='contact_wechat_btn' onClick={this.clickWechat}>复制微信号</button>
            </div>
        )
    }

    clickWechat = ()=> {
        window.webkit.messageHandlers.name.postMessage("hhh") 
        // copy('yztp321');
        alert('Mac')
        // window.location.href = 'wechat://';
        // window.chrome.webview.postMessage("GetWindowBounds");
    }
}
